<template>
    <div class="modal fade" id="modal_write_rating" tabindex="-1" aria-labelledby="modal_write_ratingLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-write-rating-content">
            <div class="modal-body">
            <div class="head-mod-container">
                <h3 class="head-modal-title">
                Rating and Review
                </h3>
            </div>
            <div class="card-content-rating">
                <form class="rating-widget">
                <input type="checkbox" class="star-input" id="1"/>
                <label class="star-input-label" for="1">1
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star orange"></i>
                </label>
                <input type="checkbox" class="star-input" id="2"/>
                <label class="star-input-label" for="2">2
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star orange"></i>
                </label>
                <input type="checkbox" class="star-input" id="3"/>
                <label class="star-input-label" for="3">3
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star orange"></i>
                </label>
                <input type="checkbox" class="star-input" id="4"/>
                <label class="star-input-label" for="4">4
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star orange"></i>
                </label>
                <input type="checkbox" class="star-input" id="5"/>
                <label class="star-input-label" for="5">5
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star orange"></i>
                </label>
                
                </form>
            </div>
            <div class="mb-3">
                <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Enter your review" rows="5"></textarea>
            </div>
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-submit" data-bs-toggle="modal">Submit</button>
                <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>